<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Specify settings for <span class="font-weight-black">{{uppercaseFirstLetter(service.name)}}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>
        <v-row>
            <v-col cols="12"
                v-for="(setting, settingName, index) in service.connectData.settings"
                :key="index">
                <v-row>
                  <v-col cols=12>
                    <v-text-field
                        :hint="setting.description"
                        persistent-hint
                        :label="setting.label"
                        v-model="clientServiceSettings[settingName]"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Ok</v-btn>
        <v-btn depressed width="130" @click="saveSettings" class="success">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Save Settings</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  props: {
    service: { required: true },
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      clientServiceSettings: {},
      saving: false,
      settingsToSave: {}
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    uppercaseFirstLetter (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    saveSettings () {
      this.saving = true
      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/integrations/' + this.$store.state.client.activeClient.clientID, { settings: this.clientServiceSettings, service: this.service.name })
        .then((response) => {
          this.saving = false
          this.show = false
          this.$emit('reload', true)
        })
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        //
      }
    }
  },
  beforeMount () {
    for (const setting in this.service.connectData.settings) {
      this.clientServiceSettings[setting] = (this.service.client.connection_data ? (this.service.client.connection_data[setting] ? this.service.client.connection_data[setting] : '') : '')
    }

    console.log(this.clientServiceSettings)
  }
})

</script>
