<template>
  <portal>
    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-share-variant</v-icon>
          <h1 class="darkGrey--text">Integrations / Social Media</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>
    <v-row v-if="!loading">

      <v-col
        cols="4"
        v-for="(item, service, index) in integrationsServices"
        :key="index"
        class="pt-0"
      >
        <v-card
          height="350"
          class="px-6 py-12 d-flex flex-column"
          :elevation="item.client.status==='connected'?6:item.client.status==='assigned'?3:1"
          :color="item.client.status==='disconnected'?'grey lighten-3':'white'"
        >
        <v-row>
          <v-col
            class="d-flex flex-column align-end pa-0">
            <social-media-why-menu :integrationService="item" :key="item.name"></social-media-why-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex flex-column align-center">
          <v-img
            max-height="70"
            contain
           :src="require('@/assets/social-' + item.name + '.svg')"
          ></v-img>

          <span class="font-weight-bold text-uppercase my-4">
            {{ item.name }}
          </span>

          <span v-if="item.client.status==='disconnected'" class="d-flex flex-column">

            <v-btn
              small
              depressed
              color="secondary"
              class="mb-2"
              height="36"
              v-if="item.connectData.authorize"
              :href="(item.connectData.authorize?item.connectData.url:'')"
            >
              Connect
            </v-btn>
            <v-btn
              small
              depressed
              color="secondary"
              class="mb-2"
              height="36"
              v-else
              @click="openSettingsModal(item)"
            >
              Settings
            </v-btn>

            <v-btn
              text
              class="darkGrey--text text-caption text-uppercase"
              @click="openModal('assign')"
              v-if="item.connectData.authorize"
            >
              Assign to another user
            </v-btn>

          </span>

          <span v-if="item.client.status==='connected'" class="d-flex flex-column">

            <span class="d-flex justify-space-between mb-2">

              <span class="d-flex flex-column mr-4"
                v-if="item.connectData.authorize">
                <span class="text-caption">Connected by</span>
                <span class="text-uppercase text-caption font-weight-bold">{{item.client.connected_by}}</span>
              </span>

<!--
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    depressed
                    color="secondary"
                    height="36"
                    width="36"
                  >
                    <v-icon
                      size="20"
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  Go to social media page
                </span>
              </v-tooltip>
-->
            </span>

            <v-btn
              small
              depressed
              color="important"
              class="white--text"
              height="36"
              @click="openModal('disconnect')"
              v-if="item.connectData.authorize"
            >
              Disconnect Account
            </v-btn>
            <v-btn
              small
              depressed
              color="secondary"
              class="mb-2"
              height="36"
              v-else
              @click="openSettingsModal(item)"
            >
              Settings
            </v-btn>

          </span>

          <span v-if="item.client.status==='assigned'" class="d-flex flex-column">

            <span class="d-flex align-center justify-center mb-2">
              <span>
                <v-icon color="important" size="32">mdi-timer-sand</v-icon>
              </span>
              <span class="d-flex flex-column">
                <span class="text-body-2">Assigned to</span>
                <span class="text-body-2">{{item.client.assigned_to}}</span>
              </span>
            </span>

            <v-btn
              text
              class="secondary--text text-caption text-uppercase"
              @click="openModal('revoke')"
            >
              Revoke assignment
            </v-btn>
          </span>
          </v-col>
        </v-row>
        </v-card>
      </v-col>

    </v-row>
    <v-overlay
      :value=loading>
      <v-progress-circular
        indeterminate
        size="128"
        color="primary"
        width="10"
      ></v-progress-circular>
    </v-overlay>

    <social-media-assign v-model="modals.assign"></social-media-assign>
    <social-media-disconnect v-model="modals.disconnect"></social-media-disconnect>
    <social-media-revoke v-model="modals.revoke"></social-media-revoke>
    <social-media-service-settings @reload="getIntegrationsServices" v-if="modals.settings" v-model="modals.settings" :service=chosenService></social-media-service-settings>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import axios from 'axios'
import SocialMediaAssign from '@/components/SocialMedia/SocialMediaAssign'
import SocialMediaDisconnect from '@/components/SocialMedia/SocialMediaDisconnect'
import SocialMediaRevoke from '@/components/SocialMedia/SocialMediaRevoke'
import SocialMediaWhyMenu from '@/components/SocialMedia/SocialMediaWhyMenu'
import SocialMediaServiceSettings from '@/components/SocialMedia/SocialMediaServiceSettings'
import Integrations from '@/models/admin/Integrations'

export default Vue.extend({
  name: 'Integrations',
  components: {
    Portal,
    SocialMediaAssign,
    SocialMediaDisconnect,
    SocialMediaRevoke,
    SocialMediaWhyMenu,
    SocialMediaServiceSettings
  },
  data: () => ({
    modals: {
      assign: false,
      revoke: false,
      disconnect: false,
      settings: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Social Media',
        disabled: true,
        href: ''
      }
    ],
    loading: true,
    clientId: null,
    integrationsServices: {},
    clientIntegrations: {},
    chosenService: {}
  }),
  computed: {
    activeIntegrations () {
      const integrations = []
      for (const service in this.integrationsServices) {
        if (this.integrationsServices[service].active) {
          integrations.push(this.integrationsServices[service])
        }
      }
      return integrations
    }
  },
  methods: {
    openModal (item) {
      this.modals[item] = true
    },
    openSettingsModal (item) {
      this.chosenService = item
      this.modals.settings = true
    },
    getIntegrationsServices () {
      this.loading = true
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/integrations/get-services')
        .then((response) => {
          this.makeIntegrationsServicesObject(response.data)
        })
        .then(() => {
          this.getClientIntegrations()
        }).catch(function (error) {
          console.log(error)
        })
    },
    getClientIntegrations () {
      this.loading = true
      Integrations
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .get()
        .then(response => {
          this.addClientIntegrationsToServices(response.data)
          this.loading = false
        })
    },
    makeIntegrationsServicesObject (receivedIntegrationsServices) {
      for (const x in receivedIntegrationsServices) {
        receivedIntegrationsServices[x].client = {
          id: null,
          client_id: null,
          service: null,
          status: 'disconnected',
          assigned_to: null,
          connected_by: null,
          connection_data: null
        }

        const serviceName = receivedIntegrationsServices[x].name
        if (receivedIntegrationsServices[x].active) {
          this.integrationsServices[serviceName] = receivedIntegrationsServices[x]
        }
      }
    },
    addClientIntegrationsToServices (clientIntegrations) {
      for (const y in clientIntegrations) {
        if (this.integrationsServices[clientIntegrations[y].service]) {
          // If there is a value on connection data, parse it to JSON
          if (clientIntegrations[y].connection_data) {
            clientIntegrations[y].connection_data = JSON.parse(clientIntegrations[y].connection_data.trim())
          }
          this.integrationsServices[clientIntegrations[y].service].client = clientIntegrations[y]
        }
      }
      console.log(this.integrationsServices)
    },
    getDataFromApi () {
      //
    },
    returnFromOauth () {
      //
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.clientId = this.$store.state.client.activeClient.clientID
          // this.getDataFromApi()
        }
      }
    })
  },
  beforeMount () {
    this.getIntegrationsServices()
  }
})
</script>
