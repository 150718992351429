<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Assign to connect to <span class="font-weight-black">Name of Social Media</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col cols="6">
            <v-text-field
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="usersSample"
              :items-per-page="5"
            >
              <template v-slot:item.name="{ item }">
            <span class="font-weight-bold">
              {{ item.name }}
            </span>
              </template>
              <template v-slot:item.email="{ item }">
                {{ item.email }}
              </template>
              <template v-slot:item.actions>
                <v-btn
                  color="transparent"
                  class="secondary--text"
                  size="18"
                  depressed
                >
                  <v-icon size="20" color="secondary">
                    mdi-link
                  </v-icon>
                  Assign
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SocialMediaAssign',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: '', value: 'actions', sortable: false, width: '140px' }
    ],
    usersSample: [
      { name: 'Rico Reis', email: 'rico@email.com' },
      { name: 'Vee Caron', email: 'vee@email.com' },
      { name: 'Charles Simon', email: 'charles@email.com' },
      { name: 'Raph Leroux', email: 'raph@email.com' },
      { name: 'Arthur da Silva', email: 'arthurdasilva@email.com' },
      { name: 'Erik Laber', email: 'erik@email.com' },
      { name: 'John Rodrigues', email: 'jrodrigues@email.com' },
      { name: 'Yedda Leite', email: 'yedda@email.com' },
      { name: 'Edward Kings', email: 'edward@email.com' },
      { name: 'Joseph Geller', email: 'joseph@email.com' },
      { name: 'Kyler Miles', email: 'kyler@email.com' },
      { name: 'Chad Jones', email: 'chad@email.com' }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
