<template>
    <v-menu
        offset-y
        open-on-hover
        class="">
        <template v-slot:activator="{ on, attrs }">
        <h6
            v-bind="attrs"
            v-on="on"
            class="text-secondary font-italic cyan--text text-darken-3"
        >
            Why connect to {{ uppercaseFirstLetter(integrationService.name) }}?
        </h6>
        </template>
        <v-list
        max-width="400">
        <v-list-item
        >
            <v-list-item-content>
            {{integrationService.whyText}}
            </v-list-item-content>
        </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    integrationService: { required: true }
  },
  data () {
    return {
    //
    }
  },
  methods: {
    uppercaseFirstLetter (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
})

</script>
